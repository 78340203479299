@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "custom-sans-fonts";
  src: url("fonts/sans_fonts/DMSans-VariableFont_opsz\,wght.ttf")
    format("truetype");
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  /* height: 20px; */
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background: #a2e6fa;
  border-radius: 40px;
}

::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background: #0d3a5c;
  height: 53px;
  border-radius: 40px;
}

::-webkit-scrollbar-thumb:hover {
  background: #0d3a5c;
}

button {
  z-index: 0;
}

.overlay {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
}

svg {
  width: 25px;
  height: 26px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 16px;
  margin-top: 24px;
  justify-items: flex-start;
}

.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
  color: white;
  border-radius: 20px 20px 0 0;
  padding: 10px;
  width: 330px;
  align-items: center;
  margin: auto;
  font-size: 20px;
  font-weight: bold;
  gap: 5px;
}
.react-calendar__navigation__label {
  color: black;
}

.react-calendar__month-view__days__day {
  flex: 0 0 11% !important;
  overflow: hidden;
  margin-inline-end: 0px;
}
.react-calendar__month-view__days {
  width: 100%;

  @media screen and (max-width: 768px) {
    width: 382px;
    margin-left: -16px;
  }
}

.react-calendar__tile {
  border-radius: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  margin: 10px;
  color: #0d3a5c;
  background-color: white;
  border: 1px solid black;
  text-transform: capitalize;
}

.react-calendar__navigation__arrow {
  font-size: 55px;
  color: #a2e6fa;
  font-weight: 200;
}

.containerIconOpen svg {
  width: 34px !important;
  height: 34px !important;
}

.iconCloseSidebar {
  height: 31px !important;
  width: 31px !important;
}
.containerIcon {
  border-radius: 10px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
}

.containerIconHover {
  background-color: #a2e6fa;
  transition: transform 0.3s ease;
  transform: scale(1.1);
}

.containerIconOpen {
  margin-left: 30px;
}

.containerIconOpen:hover {
  transition: transform 0.3s ease;
  transform: scale(1.1);
}

.containerIcon:hover {
  background-color: #a2e6fa;
  transition: transform 0.3s ease;
  transform: scale(1.1);
}

.containerIcon.isDarkModeState {
  background-color: #18507b;
}

.container-hover {
  border: 1px solid #dbf7ff;
  font-weight: bold;
}
.container-hover:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
}

th {
  padding: 10px;
  text-align: center;
  border-bottom: 3px solid;
  /* color: #ededed; */
}
table,
th,
td {
  text-align: center;
}

th,
td {
  padding: 3px;
  line-height: 1;
  /* color: #000000; */
  border-left: 4px solid #ededed;
  border-right: 4px solid #ededed;
}

.img-banks {
  width: 40px;
  height: 40px;
  border-radius: 10px;
}

.dark-mode-tile {
  background-color: transparent;
  color: #fff;
  border: 1px solid #a2e6fa;
  height: 41px;
}

.light-mode-tile {
  background-color: #fff;
  color: #000;
  border: 1px solid #0d3a5c;
  height: 41px;
}
.react-calendar__navigation__label__labelText .light-mode-tile {
  color: #8fe6ff;
}

.react-calendar__month-view__weekdays__weekday {
  font-weight: bold;
  text-align: center;
}
.react-calendar__month-view__weekdays {
  @media screen and (max-width: 768px) {
    gap: 7px;
  }
}

.react-calendar__year-view__months__month {
  border-radius: 7px;
  border-width: 2px;
}

.react-calendar__year-view__months {
  justify-content: center;
}

.time-wrapper {
  position: relative;
  font-size: 35px;
  top: 10px;
}

.react-calendar__decade-view__years {
  justify-content: center;
}

.time-wrapper .time {
  position: absolute;
  left: -30px;
  top: -229px;
  display: flex;
  transform: translateY(0);
  transition: all 0.2s;
}

.time-wrapper .time.up {
  opacity: 0;
  transform: translateY(-100%);
}

.time-wrapper .time.down {
  opacity: 0;
  transform: translateY(100%);
}

.timer-wrapper svg {
  width: 480px;
  height: 120px;
}

.countdown-number {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 38px;
}

select {
  margin: 40px;
  background: lightblue;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.selectMonthOption option {
  background-color: red !important;
  color: black;
}
*/.recharts-surface {
  width: 628px !important;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}
.App {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100vh;
}
#root {
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.marked-date-light {
  background-color: #0d3a5c;
  color: white;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
}

.marked-date-dark {
  background-color: #8fe6ff;
  color: black;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
}

.market-past-date-light {
  background-color: #9381ff;
  color: white;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
}

.market-past-date-dark {
  background: #9381ff;
  color: black;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
}

.container-operation th {
  border-color: #ededed;
  color: black;
}

.column-state {
  list-style-type: none;
}

.light-mode-table td {
  border-color: #ededed;
  color: #000;
}

.dark-mode-table td {
  border-color: #262525;
  color: #eeeeee;
}

.light-mode-table th {
  color: black;
}

.dark-mode-table th {
  color: #eeeeee;
  border-color: #262525;
}

.icon-revenue svg {
  height: 19px;
}

.special-date-light {
  background-color: #8fe6ff;
  color: black;
}
.special-date-dark {
  background-color: white;
  color: black;
}

.modalmask {
  position: fixed;
  font-family: Arial, sans-serif;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  opacity: 0;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  pointer-events: none;
}
.modalmask:target {
  opacity: 1;
  pointer-events: auto;
}

/*Formato de la ventana*/
.modalbox {
  width: 800px;
  height: 550px;
  position: relative;
  background: #fff;
  border-radius: 3px;
  -webkit-transition: all 500ms ease-in;
  -moz-transition: all 500ms ease-in;
  transition: all 500ms ease-in;

  @media screen and (max-width: 768px) {
    .modalbox {
      width: 500px;
      height: 700px;
    }
  }
}

.movedown {
  margin: 0 auto;
}
.rotate {
  margin: 10% auto;
  -webkit-transform: scale(-5, -5);
  transform: scale(-5, -5);
}
.resize {
  margin: 10% auto;
  width: 0;
  height: 0;
}
.modalmask:target .movedown {
  margin: 10% auto;
}
.modalmask:target .rotate {
  transform: rotate(360deg) scale(1, 1);
  -webkit-transform: rotate(360deg) scale(1, 1);
}

.modalmask:target .resize {
  width: 400px;
  height: 200px;
}

/*Boton de cerrar*/
.close {
  background: #606061;
  color: #ffffff;
  line-height: 25px;
  position: absolute;
  right: 1px;
  text-align: center;
  top: 1px;
  width: 24px;
  text-decoration: none;
  font-weight: bold;
  border-radius: 3px;
}

.close:hover {
  background: #faac58;
  color: #222;
}

.react-calendar__decade-view__years button {
  border-radius: 7px;
  border-width: 2px;
  justify-content: center;
}

.react-calendar__century-view__decades button {
  border-radius: 7px;
  border-width: 2px;
}
.react-calendar__century-view__decades {
  justify-content: center;
}

.modalbox {
  width: auto;
  height: auto;
  border-radius: 20px;
}
.modal-image.zoomed {
  transform: scale(1.7);
  z-index: 999;
}

.zoomable {
  transition: transform 0.3s ease-in-out;
}

.zoomable:hover {
  transform: scale(1.5);
}

.non-clickable-image {
  pointer-events: none;
}

.iconBell {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  margin-left: 25px;
  margin-right: 10px;
  cursor: pointer;
}
.iconBell svg {
  width: 30px;
  height: 30px;
}
